// Import dependencies
import React from "react"
import { navigate } from "gatsby"

// Import components
import InputField from "../form/input/inputField"
import { TitleText, ParagraphText } from "../../styled-components/UILibrary"
import GeneralButton from "../form/buttons/GeneralButton"

// Import styles
import "../../pages/forgot-password.css"

// Import Firebase
import { getFirebase } from "../../api/firebase"
import { Constants } from "../../constants/Constants"

/*
    Forgot Password Component
*/
export default class ForgotPassComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      error: null,
    }
    this.updateEmail = this.updateEmail.bind(this)
    this.requestReset = this.requestReset.bind(this)
  }

  updateEmail = e => {
    this.setState({ email: e.target.value })
  }

  requestReset = () => {
    this.setState({ error: null })

    if (this.state.email !== "") {
      const app = import("firebase/app")
      const auth = import("firebase/auth")

      Promise.all([app, auth]).then(([firebase]) => {
        getFirebase(firebase)
          .auth()
          .sendPasswordResetEmail(this.state.email)
          .then(() => {
            navigate("/forgot-password-confirmed")
          })
          .catch(error => {
            this.setState({ error: error.message })
          })
      })
    } else {
      this.setState({ error: "Please enter an email." })
    }
  }

  render() {
    return (
      <div className="forgot_password_container">
        <h1>Password assistance</h1>
        <p>Enter the email address associated with your Reinin account.</p>
        <div className="forgot_password_input_container" style={this.state.error ? {marginBottom: 4} : {}}>
          <InputField placeholder="Email address" noForm onChange={this.updateEmail} />
        </div>

        {this.state.error ? (
          <p className="forgot_password_error">
            {this.state.error}
          </p>
        ) : null}

        <div className="btn_forgot_password" onClick={this.requestReset}>Continue</div>
      </div>
    )
  }
}
