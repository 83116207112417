// Import dependencies
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

// Import components
import Layout from "../layouts/layout"
import SEO from "../components/seo/seo"
import ForgotPassComponent from "../components/account/forgotPassComponent"
import SearchBar from "../components/search-bar/searchBar";

// Import styles
import "./account.css"
import "./forgot-password.css"

/*
    Forgot Password page
*/
const ForgotPassword = () => {

  return (
    <Layout>
      <SEO title="Forgot Password" />
      <SearchBar/>
      <ForgotPassComponent />
    </Layout>
  )
}

export default ForgotPassword
